import { useSimpleReducer } from "common/hooks/useSimpleReducer";
import { Room } from "types/Room/type";
import React, { PropsWithChildren, createContext, useState } from "react";
import { DraftCharacter, DraftStep, TimerState } from "types/Draft/type";
import { CharacterBox } from "types/Player/type";

type DraftType = {
  currentStep?: DraftStep;
  timerPlayer1: number;
  timerStatePlayer1: TimerState;
  timerPlayer2: number;
  timerStatePlayer2: TimerState;
  bansPlayer1: DraftCharacter[];
  bansPlayer2: DraftCharacter[];
  picksPlayer1: DraftCharacter[];
  picksPlayer2: DraftCharacter[];
  toBanPlayer1?: DraftCharacter[];
  toBanPlayer2?: DraftCharacter[];
  selectedBansPlayer1?: DraftCharacter[];
  selectedBansPlayer2?: DraftCharacter[];
  selectedPicksPlayer1?: DraftCharacter[];
  selectedPicksPlayer2?: DraftCharacter[];
};

type DraftContextType = DraftType & {
  dispatch: (action: Partial<DraftType>) => void;
};

export const defaultValues: DraftContextType = {
  currentStep: undefined,
  timerPlayer1: 0,
  timerStatePlayer1: "Pause",
  timerPlayer2: 0,
  timerStatePlayer2: "Pause",
  bansPlayer1: [],
  bansPlayer2: [],
  picksPlayer1: [],
  picksPlayer2: [],
  toBanPlayer1: undefined,
  toBanPlayer2: undefined,
  selectedBansPlayer1: undefined,
  selectedBansPlayer2: undefined,
  selectedPicksPlayer1: undefined,
  selectedPicksPlayer2: undefined,
  dispatch: () => {},
};
export const DraftContext = createContext<DraftContextType>(defaultValues);

export const DraftProvider = (
  props: PropsWithChildren<Partial<DraftContextType>>
) => {
  const [state, dispatch] = useSimpleReducer<DraftContextType>({
    ...defaultValues,
    ...props,
  });

  return (
    <DraftContext.Provider value={{ ...state, dispatch }}>
      {props.children}
    </DraftContext.Provider>
  );
};
