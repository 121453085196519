import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { ENFlag, FRFlag } from "assets/icons";

const languages = [
  { lng: "fr", icon: <FRFlag /> },
  { lng: "en", icon: <ENFlag /> },
];

type LanguageSelectorProps = {};

const LanguageSelector = (props: LanguageSelectorProps) => {
  const { i18n } = useTranslation();

  return (
    <ToggleButtonGroup
      value={i18n.resolvedLanguage}
      exclusive
      onChange={(_, newLng: string | null) => {
        if (newLng !== null) {
          i18n.changeLanguage(newLng);
        }
      }}
      sx={{
        height: "fit-content",
        position: "absolute",
        bottom: "1rem",
        left: "1rem",
      }}
    >
      {languages.map((lng, index) => (
        <ToggleButton key={index} value={lng.lng}>
          {lng.icon}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};

export default LanguageSelector;
