import { useSimpleReducer } from "common/hooks/useSimpleReducer";
import { Room } from "types/Room/type";
import React, { PropsWithChildren, createContext, useState } from "react";
import { DraftCharacter, DraftStep, TimerState } from "types/Draft/type";
import { CharacterBox } from "types/Player/type";

type BalancingBansType = {
  selectingBans: "Player 1" | "Player 2" | undefined;
  selectedBansPlayer1?: DraftCharacter[];
  selectedBansPlayer2?: DraftCharacter[];
};

type BalancingBansContextType = BalancingBansType & {
  dispatch: (action: Partial<BalancingBansType>) => void;
};

export const defaultValues: BalancingBansContextType = {
  selectingBans: undefined,
  selectedBansPlayer1: undefined,
  selectedBansPlayer2: undefined,
  dispatch: () => {},
};
export const BalancingBansContext =
  createContext<BalancingBansContextType>(defaultValues);

export const BalancingBansProvider = (
  props: PropsWithChildren<Partial<BalancingBansContextType>>
) => {
  const [state, dispatch] = useSimpleReducer<BalancingBansContextType>({
    ...defaultValues,
    ...props,
  });

  return (
    <BalancingBansContext.Provider value={{ ...state, dispatch }}>
      {props.children}
    </BalancingBansContext.Provider>
  );
};
