import { useSocket } from "common/hooks/useSocket";
import React, { DragEvent, useState } from "react";
import {
  Button,
  CardMedia,
  Divider,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { DraftCharacter } from "types/Draft/type";
import Constellation from "common/components/Constellation";
import { StyledTypography } from "pages/Draft/PicksList";
import { DividerVertical } from "assets/icons";

type PicksListType = {
  player: "player1" | "player2";
  picks: DraftCharacter[];
  onDrop?: (order: DraftCharacter[]) => void;
};

const PicksList = (props: PicksListType) => {
  const { player, picks, onDrop } = props;

  const handleDragStart =
    (char: DraftCharacter) => (event: DragEvent<HTMLDivElement>) => {
      let fromBox = JSON.stringify(char);
      event.dataTransfer.setData("dragCharacter", fromBox);
    };

  const handleDragOver = () => (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const handleDrop =
    (toChar: DraftCharacter) => (event: DragEvent<HTMLDivElement>) => {
      if (onDrop) {
        event.preventDefault();
        let fromChar = JSON.parse(event.dataTransfer.getData("dragCharacter"));

        let tmp = picks.slice();
        let fromIndex =
          picks.findIndex((character) => character.name === fromChar.name) ??
          -1;
        let toIndex =
          picks.findIndex((character) => character.name === toChar.name) ?? -1;

        tmp[fromIndex] = toChar;
        tmp[toIndex] = fromChar;
        onDrop(tmp);
      }
    };

  return (
    <Stack
      direction="row"
      height="1vh"
      width="100%"
      flexGrow={1}
      gap={1}
      sx={{ svg: { height: "100%" } }}
    >
      {[...Array(9).keys()].map((value) =>
        value === 4 ? (
          <DividerVertical key={"-1"} />
        ) : (
          <Stack
            key={value}
            sx={{
              position: "relative",
              borderRadius: "1rem",
              overflow: "hidden",
              width: "100%",
              border: ({ palette }) =>
                `1px solid ${palette.common[player].borderConstel}`,
            }}
          >
            <CardMedia
              key={value}
              component="img"
              image={require(`assets/characters/${
                picks[value > 4 ? value - 1 : value].name
              }/drip.png`)}
              {...(onDrop !== undefined
                ? {
                    draggable: true,
                    onDragStart: handleDragStart(
                      picks[value > 4 ? value - 1 : value]
                    ),
                    onDragOver: handleDragOver(),
                    onDrop: handleDrop(picks[value > 4 ? value - 1 : value]),
                  }
                : {})}
              sx={{
                borderRadius: "1rem",
                objectPosition: "90% 10%",
                animation: "fadeIn 0.7s linear",
                animationDelay: `${0.35 * (value + 1)}s`,
                animationFillMode: "both",
                cursor: onDrop !== undefined ? "move" : "unset",
                "@keyframes fadeIn": {
                  "0%": {
                    opacity: 0,
                    transform: "scale(0.1)",
                  },
                  "85%": {
                    opacity: 1,
                    transform: "scale(1.5)",
                  },
                  "100%": {
                    transform: "scale(1.35)",
                  },
                },
              }}
            />
            <Constellation player={player}>
              {player === "player1"
                ? picks[value > 4 ? value - 1 : value].p1Constellation
                : picks[value > 4 ? value - 1 : value].p2Constellation}
            </Constellation>
          </Stack>
        )
      )}
    </Stack>
  );
};

export default PicksList;
