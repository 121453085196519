import API from "service/root/rootApi";
import { Box, Player } from "./type";

export const getPlayer = async () => {
  const response = await API.get<Player>("/adaPlayer");
  return response.data;
};

export const getToken = async () => {
  const response = await API.get<Player>("/adaToken");
  return response.data;
};

export const updatePlayer = async (player: Player) => {
  const response = await API.post<Player>("/adaPlayer", player);
  return response.data;
};

export const deleteBox = async (id: Box["id"]) => {
  const response = await API.delete(`/adaBox/${id}`);
  return response.data;
};

export const createBox = async (box: Box) => {
  const response = await API.post<Player>("/adaBox", box);
  return response.data;
};

export const updateBox = async (box: Box) => {
  const response = await API.post<Player>(`/adaBox/${box.id}`, box);
  return response.data;
};
