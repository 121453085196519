import { IconButton, Stack, TextField, Tooltip, styled } from "@mui/material";
import Typography from "common/components/Typography";
import { CHARACTERS_LIST } from "types/Character";
import { CharacterBox, Player } from "types/Player/type";
import React, { useContext, useEffect, useState } from "react";
import CharacterCard from "./CharacterCard";
import { useLocalStorage } from "common/hooks/useLocalStorage";
import { CloudDownload, CloudUpload } from "@mui/icons-material";
import { useDebouncedState } from "common/hooks/useDebounce";
import { useTranslation } from "react-i18next";
import GenshinButton from "common/components/GenshinButton";
import { DividerLeft, DividerRight } from "assets/icons";
import { NotificationContext } from "common/components/Notification";
import { useErrorNotification } from "common/components/Notification";
import { RoomContext } from "App/context";
import { createBox, getPlayer, updateBox } from "service/player";
import { Box } from "service/player/type";
import Paper from "common/components/Paper";

type BoxManagerProps = {};

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const BoxManager = (props: BoxManagerProps) => {
  const { dispatch, localPlayer } = useContext(RoomContext);
  const { dispatchNotification } = useContext(NotificationContext);
  const dispatchError = useErrorNotification();
  const { t } = useTranslation();
  const [box, setBox] = useState<Box>(
    localPlayer?.boxes?.at(0) ?? {
      id: -1,
      name: "box",
      score: 0,
      characters: [],
    }
  );
  const [search, setSearch] = useState<string>("");
  const [debouncedValue, setDebouncedValue] = useDebouncedState<string>(
    search,
    (value) => setSearch(value)
  );
  const [loading, setLoading] = useState<boolean>(false);

  const handleValidate = async () => {
    setLoading(true);
    try {
      if (localPlayer?.boxes === undefined || localPlayer?.boxes.length === 0)
        await createBox(box);
      else await updateBox(box);
      dispatch({ localPlayer: await getPlayer() });
      dispatchNotification({
        message: t("La box a été sauvegardée"),
        severity: "success",
      });
    } catch (e) {
      dispatchError(e);
    }
    setLoading(false);
  };

  const handleUploadBox = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const fileReader = new FileReader();
      fileReader.readAsText(event.target.files[0], "UTF-8");
      fileReader.onload = (e) => {
        const data: Player = JSON.parse(e.target?.result as any);
        if (data.box) setBox({ ...box, characters: data.box });
      };
    }
  };

  const handleChangeConstellation = (name: string, constellation: number) => {
    const tmp = [...box.characters];
    const index = box.characters
      .map((character) => character.name)
      .indexOf(name);
    if (index === -1) {
      tmp.push({ name, constellation });
    } else if (constellation === -1) {
      tmp.splice(index, 1);
    } else {
      tmp[index].constellation = constellation;
    }
    setBox({ ...box, characters: tmp });
  };

  useEffect(() => {
    if (localPlayer?.boxes !== undefined && localPlayer?.boxes?.length > 0) {
      setBox(localPlayer.boxes[0]);
    }
  }, [localPlayer]);

  return (
    <Stack
      direction="column"
      alignItems="center"
      width="100%"
      height="100%"
      gap={2}
    >
      <Stack
        width="100%"
        alignItems="center"
        direction="column"
        sx={{ svg: { width: "15rem" } }}
        gap={2}
      >
        <Stack direction="row" alignItems="center" gap={1}>
          <DividerRight />
          <Typography>{t("Modifier ma box")}</Typography>
          <DividerLeft />
        </Stack>
        <Stack width="100%" direction="row" justifyContent="space-between">
          <Stack>
            <TextField
              value={debouncedValue}
              onChange={(event) => setDebouncedValue(event.target.value)}
              label={t("Chercher")}
            />
          </Stack>
          <Stack sx={{ svg: { width: "auto" } }} direction="row">
            <Tooltip title={t("Importer")} arrow>
              <IconButton component="label">
                <CloudUpload />
                <VisuallyHiddenInput
                  type="file"
                  accept=".json,application/json"
                  onChange={(event) => handleUploadBox(event)}
                />
              </IconButton>
            </Tooltip>
            <Tooltip title={t("Télécharger")} arrow>
              <IconButton
                href={`data:text/json;charset=utf-8,${encodeURIComponent(
                  JSON.stringify(box.characters)
                )}`}
                download={`${box.name}.json`}
              >
                <CloudDownload />
              </IconButton>
            </Tooltip>
          </Stack>
        </Stack>
      </Stack>
      <Paper
        sx={{
          width: "100%",
          display: "grid",
          gridTemplateColumns: "repeat(auto-fill, minmax(180px, 1fr))",
          gap: (theme) => theme.spacing(2),
          overflow: "auto",
          height: "calc(100% - 250px)",
        }}
      >
        {CHARACTERS_LIST.filter((character) =>
          character.name.toLocaleLowerCase().includes(debouncedValue)
        ).map((character, index) => (
          <CharacterCard
            key={index}
            character={character}
            onChangeConstellation={handleChangeConstellation}
            constellation={
              box.characters.find((c) => c.name === character.name)
                ?.constellation
            }
          />
        ))}
      </Paper>
      <GenshinButton
        loading={loading}
        variant="contained"
        onClick={handleValidate}
      >
        {t("Sauvegarder")}
      </GenshinButton>
    </Stack>
  );
};

export default BoxManager;
