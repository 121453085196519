import {
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import GenshinButton from "common/components/GenshinButton";
import GenshinDialog from "common/components/GenshinDialog";
import { useSocket } from "common/hooks/useSocket";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

type ConfirmLeaveDraftProps = {
  open: boolean;
  setOpen: (value: boolean) => void;
};

const ConfirmLeaveDraft = (props: ConfirmLeaveDraftProps) => {
  const { open, setOpen } = props;
  const { t } = useTranslation();
  const socket = useSocket();
  const navigate = useNavigate();

  return (
    <GenshinDialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle>{t("Quitter la draft")}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t("Voulez-vous quitter la draft ?")}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <GenshinButton onClick={() => setOpen(false)}>
          {t("Annuler")}
        </GenshinButton>
        <GenshinButton
          onClick={() => {
            socket.emit("send-leave-room");
            navigate("/");
          }}
        >
          {t("Quitter")}
        </GenshinButton>
      </DialogActions>
    </GenshinDialog>
  );
};

export default ConfirmLeaveDraft;
