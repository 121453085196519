import {
  Svg4Star,
  Svg5Star,
  SvgAnemo,
  SvgCryo,
  SvgDendro,
  SvgElectro,
  SvgFR,
  SvgGeo,
  SvgHydro,
  SvgPyro,
  SvgEN,
  SvgVS,
  SvgDividerRight,
  SvgDividerLeft,
  SvgDividerHorizontal,
  SvgDividerVertical,
  SvgSortConstelDesc,
  SvgJoker,
} from "./svgs";

export const Anemo = () => SvgAnemo;
export const Geo = () => SvgGeo;
export const Pyro = () => SvgPyro;
export const Dendro = () => SvgDendro;
export const Cryo = () => SvgCryo;
export const Hydro = () => SvgHydro;
export const Electro = () => SvgElectro;
export const Icon4Star = () => Svg4Star;
export const Icon5Star = () => Svg5Star;
export const ENFlag = () => SvgEN;
export const FRFlag = () => SvgFR;
export const DividerRight = () => SvgDividerRight;
export const DividerLeft = () => SvgDividerLeft;
export const DividerHorizontal = () => SvgDividerHorizontal;
export const DividerVertical = () => SvgDividerVertical;
export const VS = () => SvgVS;
export const SortConstelDesc = () => SvgSortConstelDesc;
export const Joker = () => SvgJoker;
