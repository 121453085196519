import { RoomContext } from "App/context";
import { useErrorNotification } from "common/components/Notification";
import { JWT_TOKEN } from "common/constants";
import { useKonamiCode } from "common/hooks/useKonamiCode";
import { useSocket } from "common/hooks/useSocket";
import Draft from "pages/Draft";
import Menu from "pages/Menu";
import WaitingRoom from "pages/WaitingRoom";
import React, { useContext, useEffect } from "react";
import { useCookies } from "react-cookie";
import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom";

const AdaRoutes = () => {
  const { dispatch } = useContext(RoomContext);
  const dispatchError = useErrorNotification();
  const navigate = useNavigate();
  const konami = useKonamiCode();
  const socket = useSocket();
  const [, setCookies] = useCookies([JWT_TOKEN]);
  const routes = [
    { path: "/*", element: <Menu /> },
    { path: "/waitingRoom", element: <WaitingRoom /> },
    { path: "/draft", element: <Draft /> },
  ];

  const subscribeEvents = () => {
    socket.on("error", (message: string) => {
      dispatchError(message);
    });
  };

  useEffect(() => {
    subscribeEvents();

    return () => {
      socket.removeListener("error");
    };
  }, []);

  useEffect(() => {
    dispatch({ konami });
  }, [konami]);

  useEffect(() => {
    if (socket.disconnected) navigate("/");
  }, [socket]);

  return (
    <Routes>
      {routes.map((route, index) => (
        <Route key={index} {...route} />
      ))}
    </Routes>
  );
};

const AppRouter = () => (
  <BrowserRouter>
    <AdaRoutes />
  </BrowserRouter>
);

export default AppRouter;
