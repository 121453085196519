import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
} from "@mui/material";
import Typography from "common/components/Typography";
import React from "react";
import GenshinButton from "common/components/GenshinButton";
import { DividerHorizontal, DividerLeft, DividerRight } from "assets/icons";
import GenshinDialog from "common/components/GenshinDialog";

type UnauthorizedDialogProps = {
  open: boolean;
};

const UnauthorizedDialog = (props: UnauthorizedDialogProps) => {
  return (
    <GenshinDialog open={props.open} maxWidth="md" fullWidth>
      <DialogTitle>
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="center"
          gap={1}
          sx={{ svg: { width: "15rem" } }}
        >
          <DividerRight />
          <Typography>
            Accès non authorisé
            <br />
            Unauthorized access
          </Typography>
          <DividerLeft />
        </Stack>
      </DialogTitle>
      <DialogContent sx={{ svg: { width: "100%" } }}>
        Ton compte Discord ne possède pas les droits requis pour accéder au site
        de l'Arène des Abysses. Contacte un administrateur.
        <br />
        <DividerHorizontal />
        <br />
        Your Discord account does not have the rights required to access the
        Abyss Arena website. Contact an administrator.
      </DialogContent>
      <DialogActions>
        <GenshinButton href={process.env.REACT_APP_LOGIN_URL}>
          Relogin
        </GenshinButton>
        <GenshinButton href={process.env.REACT_APP_HOME_URL}>
          Menu
        </GenshinButton>
      </DialogActions>
    </GenshinDialog>
  );
};

export default UnauthorizedDialog;
