import React from "react";
import { CssBaseline, Stack } from "@mui/material";
import AppRouter from "./AppRouter";
import { TypedThemeProvider, createTheme } from "common/theming";
import { RoomProvider } from "./context";
import i18n from "./i18n";
import { I18nextProvider } from "react-i18next";
import NotificationProvider from "common/components/Notification";

const App = () => {
  const theme = createTheme();

  return (
    <TypedThemeProvider theme={theme}>
      <I18nextProvider i18n={i18n}>
        <NotificationProvider>
          <CssBaseline />
          <RoomProvider>
            <AppRouter />
          </RoomProvider>
        </NotificationProvider>
      </I18nextProvider>
    </TypedThemeProvider>
  );
};

export default App;
