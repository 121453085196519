import { Socket } from "socket.io-client";
import { DraftStep } from "types/Draft/type";
import { RoomPlayer } from "types/Room/type";

export const whoAMI = (
  socket: Socket,
  player1?: RoomPlayer,
  player2?: RoomPlayer
): "Player 1" | "Player 2" | "Spectator" => {
  if (socket.id === player1?.id) return "Player 1";
  else if (socket.id === player2?.id) return "Player 2";
  else return "Spectator";
};

export const isMyTurn = (
  socket: Socket,
  step?: DraftStep,
  player1?: RoomPlayer,
  player2?: RoomPlayer
) => {
  if (step === undefined) return false;
  const isPlayer1 = socket.id === player1?.id;
  const isPlayer2 = socket.id === player2?.id;
  if (
    (isPlayer1 && step.player === "Player 1") ||
    (isPlayer2 && step.player === "Player 2") ||
    ((isPlayer1 || isPlayer2) && step.player === "Both")
  )
    return true;
  return false;
};
