import { RoomContext } from "App/context";
import { useSocket } from "common/hooks/useSocket";
import React, { useContext, useEffect, useState } from "react";
import { DraftContext } from "../context";

const DraftManager = () => {
  const socket = useSocket();
  const { draft } = useContext(RoomContext);
  const {
    timerPlayer1,
    timerStatePlayer1,
    timerPlayer2,
    timerStatePlayer2,
    picksPlayer1,
    picksPlayer2,
    bansPlayer1,
    bansPlayer2,
    toBanPlayer1,
    toBanPlayer2,
  } = useContext(DraftContext);
  const [stepIndex, setStepIndex] = useState<number>(-1);

  const nextStep = () => {
    socket.emit("send-timer-state-player1", "Pause");
    socket.emit("send-timer-state-player2", "Pause");

    const newIndex = stepIndex + 1;
    if (newIndex === draft.steps.length) {
      socket.emit("send-end-draft");
    } else {
      const newStep = draft.steps[newIndex];
      socket.emit("send-step", newStep);
      setStepIndex(newIndex);

      if (newStep.player === "Player 1" || newStep.player === "Both")
        socket.emit("send-timer-state-player1", "Play");
      if (newStep.player === "Player 2" || newStep.player === "Both")
        socket.emit("send-timer-state-player2", "Play");
    }
  };

  useEffect(() => {
    let intervalIdP1: NodeJS.Timeout;
    let intervalIdP2: NodeJS.Timeout;
    if (timerStatePlayer1 === "Play" && timerPlayer1 < draft.timer * 60) {
      intervalIdP1 = setInterval(
        () => socket.emit("send-timer-player1", timerPlayer1 + 1),
        1000
      );
    }
    if (timerStatePlayer2 === "Play" && timerPlayer2 < draft.timer * 60) {
      intervalIdP2 = setInterval(
        () => socket.emit("send-timer-player2", timerPlayer2 + 1),
        1000
      );
    }
    return () => {
      clearInterval(intervalIdP1);
      clearInterval(intervalIdP2);
    };
  }, [timerPlayer1, timerStatePlayer1, timerPlayer2, timerStatePlayer2]);

  useEffect(() => {
    nextStep();
  }, []);

  useEffect(() => {
    if (
      toBanPlayer1 !== undefined &&
      toBanPlayer1.length > 0 &&
      toBanPlayer2 !== undefined &&
      toBanPlayer2.length > 0
    )
      socket.emit(
        "send-reveal-ban",
        [...bansPlayer1, ...toBanPlayer1],
        [...bansPlayer2, ...toBanPlayer2]
      );
    else if (toBanPlayer1 !== undefined && toBanPlayer1.length > 0)
      socket.emit("send-timer-state-player1", "Pause");
    else if (toBanPlayer2 !== undefined && toBanPlayer2.length > 0)
      socket.emit("send-timer-state-player2", "Pause");
  }, [toBanPlayer1, toBanPlayer2]);

  useEffect(() => {
    if (
      picksPlayer1.length > 0 ||
      bansPlayer1.length > 0 ||
      picksPlayer2.length > 0 ||
      bansPlayer2.length > 0
    ) {
      nextStep();
    }
  }, [picksPlayer1, bansPlayer1, picksPlayer2, bansPlayer2]);

  return <></>;
};

export default DraftManager;
