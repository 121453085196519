import {
  Paper as MuiPaper,
  PaperProps as MuiPaperProps,
  useTheme,
} from "@mui/material";
import React from "react";
import { PropsWithChildren } from "react";

type PaperProps = MuiPaperProps & {};

const Paper = (props: PropsWithChildren<PaperProps>) => {
  return (
    <MuiPaper
      {...props}
      sx={{
        padding: "1rem",
        bgcolor: ({ palette }) => palette.background.paper,
        ...props.sx,
      }}
    >
      {props.children}
    </MuiPaper>
  );
};

export default Paper;
