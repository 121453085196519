import { Alert, AlertColor, Portal, Snackbar } from "@mui/material";
import React, {
  PropsWithChildren,
  createContext,
  useContext,
  useReducer,
  useState,
} from "react";
import { useTranslation } from "react-i18next";

type Notification = {
  message: string;
  severity?: AlertColor;
};

interface INotificationContext {
  notifications: Notification[];
  dispatchNotification: (action: ActionNotification) => void;
}
export const NotificationContext = createContext<INotificationContext>({
  notifications: [],
  dispatchNotification: () => {},
});

const NotificationFC = () => {
  const { notifications, dispatchNotification } =
    useContext(NotificationContext);
  if (notifications.length) {
    const { message, severity = "error" } = notifications[0];

    return (
      <Portal>
        <Snackbar
          open
          autoHideDuration={4000}
          anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
          onClose={() => dispatchNotification("pop")}
        >
          <Alert variant="filled" severity={severity} sx={{ width: "100%" }}>
            {message}
          </Alert>
        </Snackbar>
      </Portal>
    );
  } else return <></>;
};

type ActionNotification = "pop" | Notification;

const NotificationProvider = (props: PropsWithChildren) => {
  const [state, dispatchNotification] = useReducer(
    (state: INotificationContext, action: ActionNotification) => {
      if (action === "pop") {
        let notifications = [...state.notifications];
        notifications.shift();
        return {
          notifications,
          dispatchNotification: state.dispatchNotification,
        };
      } else {
        let notifications = [...state.notifications];
        notifications.push({ ...action });
        return {
          notifications,
          dispatchNotification: state.dispatchNotification,
        };
      }
    },
    {
      notifications: [],
      dispatchNotification: () => {},
    }
  );
  return (
    <NotificationContext.Provider value={{ ...state, dispatchNotification }}>
      <NotificationFC />
      {props.children}
    </NotificationContext.Provider>
  );
};

export const useErrorNotification = () => {
  const { dispatchNotification } = useContext(NotificationContext);
  const { t } = useTranslation();

  const dispatchErrorNotification = (error: string) => {
    let message: string = "";

    switch (error) {
      case "room-already-exists":
        message = t("Ce salon existe déjà");
        break;
      case "player-left":
        message = t("Un joueur a quitté la draft");
        break;
      case "admin-left":
        message = t("Le créateur du salon a quitté la draft");
        break;
      default:
        message = error;
        break;
    }
    dispatchNotification({
      message,
    });
  };

  return dispatchErrorNotification;
};
export default NotificationProvider;
