import { useSocket } from "common/hooks/useSocket";
import React, { useContext, useState } from "react";
import {
  Button,
  CardMedia,
  Divider,
  Popover,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { DraftCharacter } from "types/Draft/type";
import Constellation from "common/components/Constellation";
import { DividerVertical, Joker } from "assets/icons";
import { Balance, DoNotDisturb } from "@mui/icons-material";
import { BannedCharacter } from "pages/Draft/BansList";

type AdditionalBansButtonType = {
  balancingBans?: DraftCharacter[];
  jokerBans?: DraftCharacter[];
  orientation: "left" | "right";
  color: string;
};

const AdditionalBansButton = (props: AdditionalBansButtonType) => {
  const { balancingBans, jokerBans, orientation, color } = props;
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const open = Boolean(anchorEl);
  return (
    <>
      <Button
        onClick={(event) => setAnchorEl(event.currentTarget)}
        sx={{ width: "fit-content" }}
      >
        <DoNotDisturb sx={{ height: "3rem", width: "3rem", color }} />
      </Button>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{ vertical: "center", horizontal: orientation }}
        transformOrigin={{
          vertical: "center",
          horizontal: orientation === "left" ? "right" : "left",
        }}
      >
        <Stack direction="column" gap={1}>
          {balancingBans && (
            <Stack gap={1} direction="row" padding={1}>
              <Balance />
              {balancingBans.map((char) => (
                <BannedCharacter
                  key={char.name}
                  character={char}
                  size="small"
                />
              ))}
            </Stack>
          )}
          {balancingBans && jokerBans && (
            <Divider orientation="horizontal" flexItem />
          )}
          {jokerBans && (
            <Stack gap={1} direction="row" padding={1}>
              <Joker />
              {jokerBans.map((char) => (
                <BannedCharacter
                  key={char.name}
                  character={char}
                  size="small"
                />
              ))}
            </Stack>
          )}
        </Stack>
      </Popover>
    </>
  );
};

export default AdditionalBansButton;
