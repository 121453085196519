import {
  Badge,
  Button,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
  TextField,
  Tooltip,
  useTheme,
} from "@mui/material";
import Paper from "common/components/Paper";
import Typography from "common/components/Typography";
import React, { useContext, useEffect, useState } from "react";
import { RoomContext } from "App/context";
import { Link, useNavigate } from "react-router-dom";
import { useSocket } from "common/hooks/useSocket";
import { Role, Room } from "types/Room/type";
import SelectPlayer from "./SelectPlayer";
import SpectatorsList from "./SpectatorsList";
import DraftManager from "./DraftManager";
import { useTranslation } from "react-i18next";
import Title from "pages/Menu/Navbar";
import SkewButton from "common/components/SkewButton";
import GenshinButton from "common/components/GenshinButton";
import BalancingBans from "./BalancingBans";
import {
  BalancingBansContext,
  BalancingBansProvider,
} from "./BalancingBans/context";
import { DraftCharacter } from "types/Draft/type";
import { BannedCharacter, SelectingBan } from "pages/Draft/BansList";
import FlipCoin from "./FlipCoin";
import { ContentCopy, Visibility, VisibilityOff } from "@mui/icons-material";
import FirstPartBansSelector from "./FirstPartBansSelector";
import { JokerBansContext, JokerBansProvider } from "./JokerBans/context";
import JokerBans from "./JokerBans";
import { DraftParams } from "service/draft/type";
import { getDraftParams } from "service/draft";

const WaitingRoom = () => {
  const { t } = useTranslation();
  const socket = useSocket();
  const theme = useTheme();
  const navigate = useNavigate();
  const {
    spectators,
    dispatch,
    admin,
    player1,
    player2,
    balancingBansPlayer1,
    jokerBansPlayer1,
    balancingBansPlayer2,
    jokerBansPlayer2,
    roomName,
  } = useContext(RoomContext);
  const {
    dispatch: dispatchBalancingBansContext,
    selectingBans: selectingBalancingBans,
    selectedBansPlayer1: selectedBalancingBansPlayer1,
    selectedBansPlayer2: selectedBalancingBansPlayer2,
  } = useContext(BalancingBansContext);
  const {
    dispatch: dispatchJokerBansContext,
    selectingBans: selectingJokerBans,
    selectedBansPlayer1: selectedJokerBansPlayer1,
    selectedBansPlayer2: selectedJokerBansPlayer2,
  } = useContext(JokerBansContext);
  const [showRoomName, setShowRoomName] = useState<boolean>(false);
  const [draftParams, setDraftParams] = useState<DraftParams>();
  const whoBan = (draftParams?.balancingBans ?? 0) < 0 ? "player1" : "player2";

  const subscribeEvents = () => {
    socket.on("start-draft", () => navigate("/draft"));
    socket.on("update-room", (room: Room) => {
      dispatch({
        ...room,
        player1: room.player1 ?? undefined,
        player2: room.player2 ?? undefined,
        balancingBansPlayer1: room.balancingBansPlayer1 ?? undefined,
        balancingBansPlayer2: room.balancingBansPlayer2 ?? undefined,
      });
    });
    socket.on("balanced-ban", (room: Room) => {
      dispatch({
        ...room,
      });
      dispatchBalancingBansContext({
        selectingBans: undefined,
        selectedBansPlayer1: undefined,
        selectedBansPlayer2: undefined,
      });
    });
    socket.on("joker-ban", (room: Room) => {
      dispatch({
        ...room,
      });
      dispatchJokerBansContext({
        selectingBans: undefined,
        selectedBansPlayer1: undefined,
        selectedBansPlayer2: undefined,
      });
    });
    socket.on("admin-left", () => {
      navigate("/");
    });
  };

  const checkDraftParams = async () => {
    if (player1 !== undefined && player2 !== undefined) {
      setDraftParams(
        await getDraftParams(player1.score ?? 0, player2.score ?? 0)
      );
    }
  };

  useEffect(() => {
    subscribeEvents();
    socket.emit("get-room");

    return () => {
      socket.removeListener("start-draft");
      socket.removeListener("update-room");
      socket.removeListener("balanced-ban");
      socket.removeListener("admin-left");
    };
  }, []);

  useEffect(() => {
    if (player1 !== undefined && player2 !== undefined) checkDraftParams();
    else setDraftParams(undefined);
  }, [player1, player2]);

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      height="100%"
      flexWrap="nowrap"
    >
      <Typography size="xl">{t("Salle d'attente")}</Typography>
      <Stack direction="row" gap={2}>
        <FormControl variant="outlined" sx={{ width: "20rem" }} disabled>
          <InputLabel>{t("Nom du salon")}</InputLabel>
          <OutlinedInput
            value={showRoomName ? roomName : "*******"}
            endAdornment={
              <InputAdornment position="end" sx={{ gap: "1rem" }}>
                <IconButton
                  onClick={() => setShowRoomName(!showRoomName)}
                  edge="end"
                >
                  {showRoomName ? <VisibilityOff /> : <Visibility />}
                </IconButton>
                <IconButton
                  onClick={async () =>
                    await navigator.clipboard.writeText(roomName)
                  }
                >
                  <ContentCopy />
                </IconButton>
              </InputAdornment>
            }
            label={t("Nom du salon")}
          />
        </FormControl>
      </Stack>
      <Grid item height="100%" width="100%" flexGrow={1}>
        <Stack
          direction="row"
          gap={3}
          // gridTemplateColumns="repeat(3, minmax(0, 1fr))"
          gridTemplateColumns="23rem auto 23rem"
          display="grid"
          padding="1rem"
          height="100%"
        >
          <Stack direction="column" justifyContent="space-between">
            <Stack gap={1}>
              <SelectPlayer
                player={player1}
                label={t("Joueur {{number}}", { number: 1 })}
                color={"player1"}
                onClick={() => socket.emit("select-player1")}
              />
              {(selectedBalancingBansPlayer1 || balancingBansPlayer1) && (
                <Typography size="sm">{t("Bans d'équilibrage :")}</Typography>
              )}
              <Stack
                display="grid"
                gridTemplateColumns="repeat(auto-fill, minmax(85px, 1fr))"
                gap={1}
              >
                {selectedBalancingBansPlayer1?.map((char) => (
                  <SelectingBan key={char.name} character={char} />
                ))}
                {balancingBansPlayer1 &&
                  balancingBansPlayer1.map((char) => (
                    <BannedCharacter key={char.name} character={char} />
                  ))}
              </Stack>
              {(selectedJokerBansPlayer1 || jokerBansPlayer1) && (
                <Typography size="sm">{t("Bans joker :")}</Typography>
              )}
              <Stack
                display="grid"
                gridTemplateColumns="repeat(auto-fill, minmax(85px, 1fr))"
                gap={1}
              >
                {selectedJokerBansPlayer1?.map((char) => (
                  <SelectingBan key={char.name} character={char} />
                ))}
                {jokerBansPlayer1 &&
                  jokerBansPlayer1.map((char) => (
                    <BannedCharacter key={char.name} character={char} />
                  ))}
              </Stack>
            </Stack>
            <Link to={"/"}>
              <GenshinButton variant="contained">{t("Quitter")}</GenshinButton>
            </Link>
          </Stack>

          <Stack direction="column" alignItems="center" gap={1}>
            <Badge
              color={whoBan === "player1" ? "info" : "error"}
              badgeContent={draftParams?.firstPartBans}
            >
              <FirstPartBansSelector />
            </Badge>
            <FlipCoin />
            <Stack direction="row" height="100%" gap={1}>
              {selectedJokerBansPlayer1 === undefined &&
                selectedJokerBansPlayer2 === undefined && (
                  <BalancingBans
                    badgeQuantity={draftParams?.balancingBans}
                    whoBan={whoBan}
                  />
                )}
              {selectedBalancingBansPlayer1 === undefined &&
                selectedBalancingBansPlayer2 === undefined && (
                  <JokerBans
                    badgeQuantity={draftParams?.jokerBans}
                    whoBan={whoBan}
                  />
                )}
            </Stack>
            {admin && (
              <SkewButton
                variant="contained"
                disabled={
                  player1 === undefined ||
                  player2 === undefined ||
                  selectingBalancingBans !== undefined ||
                  selectingJokerBans !== undefined
                }
                onClick={() => socket.emit("send-start-draft")}
                sx={{
                  width: "fit-content",
                  margin: "auto",
                  padding: "0.75rem 5rem",
                }}
              >
                {t("Commencer")}
              </SkewButton>
            )}
          </Stack>

          <Stack direction="column" justifyContent="space-between">
            <Stack gap={1}>
              <SelectPlayer
                player={player2}
                label={t("Joueur {{number}}", { number: 2 })}
                color={"player2"}
                onClick={() => socket.emit("select-player2")}
              />
              {(selectedBalancingBansPlayer2 || balancingBansPlayer2) && (
                <Typography size="sm">{t("Bans d'équilibrage :")}</Typography>
              )}
              <Stack
                display="grid"
                gridTemplateColumns="repeat(auto-fill, minmax(85px, 1fr))"
                gap={1}
              >
                {selectedBalancingBansPlayer2?.map((char) => (
                  <SelectingBan key={char.name} character={char} />
                ))}
                {balancingBansPlayer2 &&
                  balancingBansPlayer2.map((char) => (
                    <BannedCharacter key={char.name} character={char} />
                  ))}
              </Stack>
              {(selectedJokerBansPlayer2 || jokerBansPlayer2) && (
                <Typography size="sm">{t("Bans joker :")}</Typography>
              )}
              <Stack
                display="grid"
                gridTemplateColumns="repeat(auto-fill, minmax(85px, 1fr))"
                gap={1}
              >
                {selectedJokerBansPlayer2?.map((char) => (
                  <SelectingBan key={char.name} character={char} />
                ))}
                {jokerBansPlayer2 &&
                  jokerBansPlayer2.map((char) => (
                    <BannedCharacter key={char.name} character={char} />
                  ))}
              </Stack>
            </Stack>
            <SpectatorsList
              spectators={spectators}
              onClick={() => socket.emit("select-spectator")}
            />
          </Stack>
        </Stack>
      </Grid>
    </Grid>
  );
};

const Wrapper = () => {
  return (
    <BalancingBansProvider>
      <JokerBansProvider>
        <WaitingRoom />
      </JokerBansProvider>
    </BalancingBansProvider>
  );
};

export default Wrapper;
