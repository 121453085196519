import {
  Dialog as MuiDialog,
  DialogProps as MuiDialogProps,
} from "@mui/material";
import React from "react";
import { PropsWithChildren } from "react";

type GenshinDialogProps = MuiDialogProps & {
  height?: string;
};

const GenshinDialog = (props: PropsWithChildren<GenshinDialogProps>) => {
  return (
    <MuiDialog
      {...props}
      sx={{
        ".MuiDialog-paper": {
          height: props.height,
          padding: "1rem",
          backgroundColor: "transparent",
          backgroundImage: "url(./background-dialog.svg)",
          backgroundSize: "100% 100%",
        },
        ...props.sx,
      }}
    >
      {props.children}
    </MuiDialog>
  );
};

export default GenshinDialog;
