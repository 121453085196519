import {
  CardMedia,
  Divider,
  IconButton,
  Stack,
  Tooltip,
  Typography,
  TypographyProps,
  styled,
} from "@mui/material";
import Paper from "common/components/Paper";
import React, { useContext } from "react";
import { CharacterBox } from "types/Player/type";
import { DraftContext } from "../context";
import { DraftCharacter } from "types/Draft/type";
import Constellation from "common/components/Constellation";
import { RoomContext } from "App/context";
import { Balance, Close, DoNotDisturb, Info } from "@mui/icons-material";
import { DividerHorizontal, Joker } from "assets/icons";
import { BannedCharacter } from "../BansList";

const PaperStyled = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.background.dark,
  height: "11.5vh",
  padding: "0",
  overflow: "hidden",
}));
const PulsingPaper = styled(PaperStyled)(({ theme }) => ({
  "@keyframes pulse-pick": {
    "0%, 100%": {
      backgroundColor: "transparent",
    },
    "50%": {
      backgroundColor: theme.palette.common.grey.dark,
    },
  },
  animation: "pulse-pick 2s infinite",
}));
const BackgroundCard = styled(CardMedia)(({ theme }) => ({
  backgroundImage: `url(/background-gacha.jpg)`,
  position: "relative",
  borderRadius: "1rem",
}));
export const StyledTypography = styled(
  ({
    player,
    ...props
  }: {
    player: "player1" | "player2";
  } & TypographyProps) => (
    <Typography
      color={`common.${player}.text`}
      sx={{
        left: player === "player2" ? "0.5rem" : "unset",
        right: player === "player1" ? "0.5rem" : "unset",
        bgcolor: ({ palette }) => palette.background.default,
        width: "fit-content",
      }}
      {...props}
    />
  )
)(() => ({
  position: "absolute",
  bottom: 0,
  fontSize: "1rem",
  right: "0.5rem",
  padding: "0 0.2rem",
  borderRadius: "0.5rem",
}));
const getImage = (characterName: string, konami: boolean) => {
  if (konami) {
    try {
      return require(`assets/characters/${characterName}/cosplay.gif`);
    } catch (e) {}
  }
  return require(`assets/characters/${characterName}/splash.png`);
};
const CardMediaStyled = styled(
  ({
    characterName,
    konami,
    ...props
  }: {
    characterName: string;
    konami: boolean;
  }) => (
    <CardMedia
      component="img"
      image={getImage(characterName, konami)}
      {...props}
    />
  )
)(() => ({
  objectPosition: "85% 15%",
  borderRadius: "0.5rem",
  transform: "scale(1.4)",
}));

type PicksListType = {
  player: "player1" | "player2";
  playerName: string;
  picks: DraftCharacter[];
  balancingBans?: DraftCharacter[];
  jokerBans?: DraftCharacter[];
  selected?: DraftCharacter[];
  onClick?: (character: DraftCharacter) => void;
};

const PicksList = (props: PicksListType) => {
  const { konami } = useContext(RoomContext);
  const { currentStep } = useContext(DraftContext);
  const {
    player,
    playerName,
    picks,
    selected,
    balancingBans,
    jokerBans,
    onClick,
  } = props;

  const getCharacterCard = (index: number) => {
    if (picks[index] !== undefined) {
      return (
        <BackgroundCard
          key={index}
          sx={{
            overflow: "hidden",
          }}
        >
          <PaperStyled
            sx={{
              backgroundColor: "transparent",
            }}
          >
            {player === "player1" &&
              picks[index].p1Constellation !== undefined && (
                <Constellation player="player1">
                  {picks[index].p1Constellation}
                </Constellation>
              )}
            {player === "player2" &&
              picks[index].p2Constellation !== undefined && (
                <Constellation player="player2">
                  {picks[index].p2Constellation}
                </Constellation>
              )}
            <CardMediaStyled
              characterName={picks[index].name}
              konami={konami}
            />
          </PaperStyled>
        </BackgroundCard>
      );
    } else if (
      selected !== undefined &&
      index - picks.length < (currentStep?.quantity ?? 0)
    ) {
      const character = selected[index - picks.length];
      return (
        <BackgroundCard key={index}>
          <PulsingPaper>
            {character !== undefined ? (
              <>
                <Stack
                  sx={{
                    height: "100%",
                    width: "100%",
                    borderRadius: "12px",
                    position: "absolute",
                    overflow: "hidden",
                  }}
                >
                  {player === "player1" &&
                    character.p1Constellation !== undefined && (
                      <Constellation player="player1">
                        {character.p1Constellation}
                      </Constellation>
                    )}
                  {player === "player2" &&
                    character.p2Constellation !== undefined && (
                      <Constellation player="player2">
                        {character.p2Constellation}
                      </Constellation>
                    )}
                </Stack>
                <CardMediaStyled
                  characterName={character.name}
                  konami={konami}
                />
                {onClick && (
                  <Stack
                    alignItems="center"
                    sx={{
                      position: "absolute",
                      top: "0px",
                      right: player === "player1" ? "-12px" : "",
                      left: player === "player2" ? "-12px" : "",
                      height: "100%",
                    }}
                  >
                    <IconButton
                      onClick={() => onClick(character)}
                      sx={{
                        padding: "2px",
                        margin: "auto",
                        bgcolor: ({ palette }) =>
                          palette.common.player2.banCard,
                        "&:hover": {
                          bgcolor: ({ palette }) =>
                            palette.common.player2.paper,
                        },
                      }}
                    >
                      <Close />
                    </IconButton>
                  </Stack>
                )}
              </>
            ) : (
              ""
            )}
          </PulsingPaper>
        </BackgroundCard>
      );
    } else {
      return <PaperStyled key={index} />;
    }
  };

  return (
    <Paper
      sx={{
        width: "15rem",
        padding: "0.5rem",
      }}
    >
      <Stack
        direction={player === "player1" ? "row" : "row-reverse"}
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography
          textOverflow="ellipsis"
          overflow="hidden"
          whiteSpace="nowrap"
          color={`common.${player}.text`}
        >
          {playerName}
        </Typography>
        {(balancingBans || jokerBans) && (
          <Tooltip
            arrow
            title={
              <React.Fragment>
                <Stack direction="column" gap={1}>
                  {balancingBans && (
                    <>
                      <Balance />
                      <Stack
                        display="grid"
                        gridTemplateColumns="repeat(2, minmax(70px, 1fr))"
                        gap={1}
                      >
                        {balancingBans.map((char) => (
                          <BannedCharacter
                            key={char.name}
                            character={char}
                            size="small"
                          />
                        ))}
                      </Stack>
                    </>
                  )}
                  {balancingBans && jokerBans && (
                    <Divider orientation="horizontal" flexItem />
                  )}
                  {jokerBans && (
                    <>
                      <Joker />
                      <Stack
                        display="grid"
                        gridTemplateColumns="repeat(2, minmax(70px, 1fr))"
                        gap={1}
                      >
                        {jokerBans.map((char) => (
                          <BannedCharacter
                            key={char.name}
                            character={char}
                            size="small"
                          />
                        ))}
                      </Stack>
                    </>
                  )}
                </Stack>
              </React.Fragment>
            }
          >
            <DoNotDisturb />
          </Tooltip>
        )}
      </Stack>
      <Stack
        direction="column"
        flexGrow={1}
        height="calc(100% - 20px)"
        justifyContent="space-between"
        sx={{ svg: { width: "100%" } }}
      >
        {[...Array(9).keys()].map((value) =>
          value === 4 ? (
            <Divider key={"-1"} orientation="horizontal" />
          ) : (
            getCharacterCard(value > 4 ? value - 1 : value)
          )
        )}
      </Stack>
    </Paper>
  );
};

export default PicksList;
