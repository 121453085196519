import GenshinImpact from "assets/fonts/Genshin_Impact_Font/zh-cn.ttf";

interface Font {
  url: typeof GenshinImpact;
  weight: string;
  local: string;
}

const getFontFace = () => `
@font-face {
  font-family: 'GenshinImpact';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: local('GenshinImpact'), url(${GenshinImpact}) format('truetype');
}
`;

export default getFontFace();
