import { AccountCircle, PersonAddAlt1 } from "@mui/icons-material";
import {
  FormControl,
  IconButton,
  InputLabel,
  OutlinedInput,
  Stack,
  TextField,
} from "@mui/material";
import { RoomContext } from "App/context";
import Avatar from "common/components/Avatar";
import Typography from "common/components/Typography";
import { useSocket } from "common/hooks/useSocket";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Draft, DraftStep } from "types/Draft/type";
import { RoomPlayer } from "types/Room/type";

type FirstPartBansSelectorType = {};

const FirstPartBansSelector = (props: FirstPartBansSelectorType) => {
  const { t } = useTranslation();
  const socket = useSocket();
  const { draft, admin } = useContext(RoomContext);
  const {} = props;

  let banCount = 0;
  if (draft.steps.length > 0) {
    while (draft.steps[banCount].action === "Ban") {
      banCount++;
    }
  }
  banCount /= 2;

  return (
    <TextField
      disabled={!admin}
      label={t("Bans en 1ère partie")}
      type="number"
      value={banCount}
      onChange={(evt) => {
        if (evt.target.value !== undefined) {
          const value = parseInt(evt.target.value);
          if (value >= 2 && value <= 4) {
            let bans: DraftStep[] = [];
            for (let i = 0; i < value; i++) {
              bans.push(
                { player: "Player 1", action: "Ban", quantity: 1 },
                { player: "Player 2", action: "Ban", quantity: 1 }
              );
            }
            bans.push();
            socket.emit("update-draft", {
              ...draft,
              steps: [...bans, ...draft.steps.slice(banCount * 2)],
            });
          }
        }
      }}
      sx={{ width: "fit-content" }}
    />
  );
};

export default FirstPartBansSelector;
