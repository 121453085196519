import React from "react";
import { CircularProgress, Avatar as MuiAvatar } from "@mui/material";
import Profile from "pages/Menu/Profile";

type AvatarType = {
  loading?: boolean;
  character?: string;
};

const Avatar = (props: AvatarType) => {
  const { character, loading = false } = props;

  return (
    <MuiAvatar
      src={
        character
          ? require(`assets/characters/${character}/avatar.png`)
          : undefined
      }
      sx={{
        height: "4rem",
        width: "4rem",
      }}
    >
      {loading ? <CircularProgress /> : <Profile />}
    </MuiAvatar>
  );
};

export default Avatar;
