import { useSimpleReducer } from "common/hooks/useSimpleReducer";
import { Room } from "types/Room/type";
import React, { PropsWithChildren, createContext, useState } from "react";
import { Player } from "service/player/type";

type RoomSocket = Room & {
  localPlayer?: Player;
  admin: boolean;
  roomName: string;
  konami: boolean;
};

type RoomContextType = RoomSocket & {
  dispatch: (action: Partial<RoomSocket>) => void;
};

export const defaultValues: RoomContextType = {
  roomName: "",
  localPlayer: undefined,
  draft: { banShared: false, pickShared: false, timer: 0, steps: [] },
  spectators: [],
  dispatch: () => {},
  player1: undefined,
  player2: undefined,
  balancingBansPlayer1: undefined,
  jokerBansPlayer1: undefined,
  balancingBansPlayer2: undefined,
  jokerBansPlayer2: undefined,
  admin: false,
  konami: false,
};
export const RoomContext = createContext<RoomContextType>(defaultValues);

export const RoomProvider = (
  props: PropsWithChildren<Partial<RoomContextType>>
) => {
  const [state, dispatch] = useSimpleReducer<RoomContextType>({
    ...defaultValues,
    ...props,
  });

  return (
    <RoomContext.Provider value={{ ...state, dispatch }}>
      {props.children}
    </RoomContext.Provider>
  );
};
