import { ContentCopy } from "@mui/icons-material";
import {
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material";
import { RoomContext } from "App/context";
import Paper from "common/components/Paper";
import Typography from "common/components/Typography";
import { useLocalStorage } from "common/hooks/useLocalStorage";
import { useSocket } from "common/hooks/useSocket";
import { RoomPlayer } from "types/Room/type";
import { Player } from "types/Player/type";
import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useErrorNotification } from "common/components/Notification";
import GenshinButton from "common/components/GenshinButton";

type CreateJoinRoomProps = {};

const CreateJoinRoom = (props: CreateJoinRoomProps) => {
  const { t } = useTranslation();
  const socket = useSocket();
  const navigate = useNavigate();
  const [roomName, setRoomName] = useState<string>("");
  const { dispatch, localPlayer } = useContext(RoomContext);

  const subscribeEvents = () => {
    socket.on("join-room", (admin?: boolean) => {
      if (admin) dispatch({ admin: true });
      navigate("/waitingRoom");
    });
  };

  useEffect(() => {
    subscribeEvents();

    return () => {
      socket.removeListener("join-room");
    };
  }, []);

  return (
    <Paper>
      <Stack gap="1rem">
        <Typography>{t("Créer ou rejoindre un salon")}</Typography>
        <FormControl variant="outlined" size="small" required>
          <InputLabel>{t("Nom du salon")}</InputLabel>
          <OutlinedInput
            required
            value={roomName}
            onChange={(event) => setRoomName(event.target.value)}
            label={t("Nom du salon")}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  onClick={async () =>
                    await navigator.clipboard.writeText(roomName)
                  }
                >
                  <ContentCopy />
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
        <Tooltip
          arrow
          title={
            localPlayer?.pseudo === undefined ||
            localPlayer?.boxes === undefined ||
            localPlayer?.boxes.length === 0
              ? t("Ta box et ton pseudo doivent être configurés")
              : roomName === ""
              ? t("Le nom du salon ne peut pas être vide")
              : ""
          }
        >
          <span>
            <GenshinButton
              fullWidth
              disabled={
                roomName === "" ||
                localPlayer?.pseudo === undefined ||
                localPlayer?.boxes === undefined ||
                localPlayer?.boxes.length === 0
              }
              variant="contained"
              onClick={() => {
                const player: RoomPlayer = {
                  id: socket.id,
                  pseudo: localPlayer?.pseudo,
                  box: localPlayer?.boxes?.at(0)?.characters,
                  score: localPlayer?.boxes?.at(0)?.score,
                  avatar: localPlayer?.avatar,
                };
                dispatch({ roomName: roomName });
                socket.emit("send-join-room", roomName, player);
              }}
            >
              {t("Rejoindre")}
            </GenshinButton>
          </span>
        </Tooltip>
      </Stack>
    </Paper>
  );
};

export default CreateJoinRoom;
