import { useSimpleReducer } from "common/hooks/useSimpleReducer";
import { Room } from "types/Room/type";
import React, { PropsWithChildren, createContext, useState } from "react";
import { DraftCharacter, DraftStep, TimerState } from "types/Draft/type";
import { CharacterBox } from "types/Player/type";

type JokerBansType = {
  selectingBans: "Player 1" | "Player 2" | undefined;
  selectedBansPlayer1?: DraftCharacter[];
  selectedBansPlayer2?: DraftCharacter[];
};

type JokerBansContextType = JokerBansType & {
  dispatch: (action: Partial<JokerBansType>) => void;
};

export const defaultValues: JokerBansContextType = {
  selectingBans: undefined,
  selectedBansPlayer1: undefined,
  selectedBansPlayer2: undefined,
  dispatch: () => {},
};
export const JokerBansContext =
  createContext<JokerBansContextType>(defaultValues);

export const JokerBansProvider = (
  props: PropsWithChildren<Partial<JokerBansContextType>>
) => {
  const [state, dispatch] = useSimpleReducer<JokerBansContextType>({
    ...defaultValues,
    ...props,
  });

  return (
    <JokerBansContext.Provider value={{ ...state, dispatch }}>
      {props.children}
    </JokerBansContext.Provider>
  );
};
