import {
  Button as MuiButton,
  ButtonProps as MuiButtonProps,
  useTheme,
} from "@mui/material";
import React from "react";
import { PropsWithChildren } from "react";
import Typography from "../Typography";

type SkewButtonProps = MuiButtonProps & {};

const SkewButton = (props: PropsWithChildren<SkewButtonProps>) => {
  const { disabled } = props;
  return (
    <MuiButton
      {...props}
      sx={({ palette }) => ({
        borderBottomLeftRadius: "0",
        borderTopRightRadius: "0",
        px: "2rem",
        border: disabled
          ? "1px solid gray"
          : `1px solid ${palette.common.player2.text}`,
        transform: "skew(-30deg)",
        bgcolor: disabled
          ? palette.common.player2.paper
          : palette.common.player2.text,
        ":hover": {
          bgcolor: palette.common.player2.paper,
        },
        ...props.sx,
      })}
    >
      <Typography
        size="sm"
        sx={{
          color: disabled ? "gray" : "white",
          transform: "skew(30deg)",
        }}
      >
        {props.children}
      </Typography>
    </MuiButton>
  );
};

export default SkewButton;
