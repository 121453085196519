import {
  CircularProgress,
  Button as MuiButton,
  ButtonProps as MuiButtonProps,
  useTheme,
} from "@mui/material";
import React from "react";
import { PropsWithChildren } from "react";
import Typography from "../Typography";

type GenshinButtonProps = MuiButtonProps & {
  loading?: boolean;
};

const GenshinButton = (props: PropsWithChildren<GenshinButtonProps>) => {
  const { disabled, loading = false } = props;
  return (
    <MuiButton
      {...props}
      variant="contained"
      disabled={disabled || loading}
      startIcon={loading && <CircularProgress color="inherit" size="1.5rem" />}
      sx={({ palette }) => ({
        borderRadius: "3rem",
        px: "2rem",
        border: disabled
          ? "1px solid gray"
          : `1px solid ${palette.background.button}`,
        bgcolor: palette.background.button,
        ":hover": {
          bgcolor: palette.background.button,
        },
        ...props.sx,
      })}
    >
      <Typography
        size="sm"
        sx={{
          color: disabled ? "gray" : "black",
        }}
      >
        {props.children}
      </Typography>
    </MuiButton>
  );
};

export default GenshinButton;
