import { useSocket } from "common/hooks/useSocket";
import React, { PropsWithChildren, useContext } from "react";
import { Box, Button, Typography } from "@mui/material";
import { isMyTurn, whoAMI } from "common/utils";
import { RoomContext } from "App/context";
import { CharacterBox } from "types/Player/type";
import { DraftCharacter } from "types/Draft/type";

type ConstellationType = {
  player: "player1" | "player2";
};

const Constellation = (props: PropsWithChildren<ConstellationType>) => {
  const { player } = props;

  return (
    <Box
      component="div"
      sx={{
        position: "absolute",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        top: "-1px",
        left: player === "player1" ? "-1px" : "unset",
        right: player === "player2" ? "-1px" : "unset",
        zIndex: "1",
        borderBottomRightRadius: player === "player1" ? "0.7rem" : "unset",
        borderBottomLeftRadius: player === "player2" ? "0.7rem" : "unset",
        width: 30,
        height: 30,
        color: "white",
        bgcolor: ({ palette }) => palette.common[player].bgConstel,
        border: ({ palette }) =>
          `1px solid ${palette.common[player].borderConstel}`,
      }}
    >
      <Typography>{props.children}</Typography>
    </Box>
  );
};

export default Constellation;
