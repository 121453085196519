import { ThemeOptions, outlinedInputClasses } from "@mui/material";
import { Fade } from "@mui/material";

import GenshinImpact from "./GenshinImpact";

export const radius: Required<ThemeOptions>["radius"] = {
  smaller: "2px",
  small: "4px",
  medium: "8px",
};
export const components: ThemeOptions["components"] = {
  MuiTooltip: {
    defaultProps: {
      TransitionComponent: Fade,
    },
  },
  MuiSelect: {
    defaultProps: {
      size: "small",
    },
  },
  MuiTextField: {
    defaultProps: {
      size: "small",
    },
  },
  MuiCssBaseline: {
    styleOverrides: `
    ${GenshinImpact},
    *::-webkit-scrollbar {
      width: 0.5rem;
      height: 0.5rem;
    },
    *::-webkit-scrollbar-track {
      background: transparent;
    },
    *::-webkit-scrollbar-thumb {
      background-color: #707070;
      border-radius: 1rem;
    },
    ::-webkit-scrollbar-corner {
      background-color: transparent;
    },
  `,
  },
  MuiSwitch: {
    styleOverrides: {
      root: {
        height: 26,
        width: 56,
        padding: 0,
      },
      switchBase: ({ theme }) => ({
        padding: 0,
        margin: 2,
        "&.Mui-checked": {
          transform: "translateX(30px)",
          color: theme.palette.background.default,
          "&.Mui-disabled": {
            color: theme.palette.background.default,
          },
        },
        "&.Mui-checked+.MuiSwitch-track": {
          opacity: 1,
        },
        "&.Mui-disabled+.MuiSwitch-track": {
          backgroundColor: theme.palette.common.grey.dark,
        },
      }),
      track: ({ theme }) => ({
        borderRadius: 26 / 2,
        backgroundColor: theme.palette.grey[800],
        opacity: 1,
        transition: theme.transitions.create(["background-color"], {
          duration: 500,
        }),
      }),
      thumb: {
        boxSizing: "border-box",
        width: 22,
        height: 22,
      },
    },
  },
};
