import { PersonAddAlt1 } from "@mui/icons-material";
import { Box, Button, Divider, Grid, IconButton, Stack } from "@mui/material";
import Paper from "common/components/Paper";
import Typography from "common/components/Typography";
import React from "react";
import { useTranslation } from "react-i18next";
import { RoomPlayer } from "types/Room/type";

type SpectatorsListType = {
  spectators: RoomPlayer[];
  onClick: () => void;
};

const SpectatorsList = (props: SpectatorsListType) => {
  const { t } = useTranslation();
  const { spectators, onClick } = props;

  return (
    <Paper sx={{ padding: 1, height: "13rem" }}>
      <Stack alignItems="center" direction="column" gap={0.5}>
        <Stack
          direction="row"
          width="100%"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography size="sm" color="gray">
            {t("En attente de joueurs...")}
          </Typography>
          <IconButton onClick={onClick}>
            <PersonAddAlt1 />
          </IconButton>
        </Stack>
        <Divider flexItem orientation="horizontal" />
        <Box overflow="auto" width="100%" height="9rem">
          {spectators.map((player) => (
            <Typography
              key={player.id}
              textAlign="center"
              textOverflow="ellipsis"
              overflow="hidden"
              whiteSpace="nowrap"
            >
              {player.pseudo}
            </Typography>
          ))}
        </Box>
      </Stack>
    </Paper>
  );
};

export default SpectatorsList;
