import {
  Button,
  CardMedia,
  DialogContent,
  DialogTitle,
  Divider,
  Slide,
  Stack,
  TextField,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import Typography from "common/components/Typography";
import { CHARACTERS_LIST } from "types/Character";
import { Player } from "types/Player/type";
import React, { useContext, useEffect, useState } from "react";
import { useLocalStorage } from "common/hooks/useLocalStorage";
import { useTranslation } from "react-i18next";
import Paper from "common/components/Paper";
import GenshinButton from "common/components/GenshinButton";
import { DividerLeft, DividerRight } from "assets/icons";
import GenshinDialog from "common/components/GenshinDialog";
import { RoomContext } from "App/context";
import {
  NotificationContext,
  useErrorNotification,
} from "common/components/Notification";
import { getPlayer, updatePlayer } from "service/player";

type AvatarSelectorProps = {
  open: boolean;
  onClose: Function;
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const AvatarSelector = (props: AvatarSelectorProps) => {
  const { t } = useTranslation();
  const { dispatch, localPlayer } = useContext(RoomContext);
  const { dispatchNotification } = useContext(NotificationContext);
  const dispatchError = useErrorNotification();

  const [pseudo, setPseudo] = useState<string>(localPlayer?.pseudo ?? "");
  const [avatar, setAvatar] = useState<string>(localPlayer?.avatar ?? "");
  const [loading, setLoading] = useState<boolean>(false);

  const handleValidate = async () => {
    setLoading(true);
    try {
      await updatePlayer({ pseudo, avatar });
      dispatch({ localPlayer: await getPlayer() });
      dispatchNotification({
        message: t("Ton profil a été sauvegardé"),
        severity: "success",
      });
      props.onClose();
    } catch (e) {
      setLoading(false);
      dispatchError(e);
    }
  };

  useEffect(() => {
    if (props.open) {
      setLoading(false);
      setPseudo(localPlayer?.pseudo ?? "");
      setAvatar(localPlayer?.avatar ?? "");
    }
  }, [props.open]);

  return (
    <GenshinDialog
      open={props.open}
      TransitionComponent={Transition}
      onClose={() => props.onClose()}
      fullWidth
      maxWidth="lg"
      height="70vh"
    >
      <DialogTitle>
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="center"
          gap={1}
          sx={{ svg: { width: "15rem" } }}
        >
          <DividerRight />
          <Typography>{t("Profil")}</Typography>
          <DividerLeft />
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Stack direction="row" height="100%">
          <Paper
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(auto-fill, minmax(110px, 1fr))",
              gap: (theme) => theme.spacing(2),
              flexGrow: 1,
              overflow: "auto",
              height: "100%",
            }}
          >
            {CHARACTERS_LIST.map((character, index) => {
              try {
                return (
                  <Button
                    key={index}
                    onClick={() => setAvatar(character.name)}
                    sx={{
                      border:
                        character.name === avatar ? "5px solid white" : "",
                      borderRadius: character.name === avatar ? "100%" : "",
                    }}
                  >
                    <CardMedia
                      component="img"
                      image={require(`assets/characters/${character.name}/avatar.png`)}
                      title={character.name}
                    />
                  </Button>
                );
              } catch (error) {}
            })}
          </Paper>
          <Stack
            direction="column"
            justifyContent="space-between"
            padding="2rem"
          >
            <Stack direction="column" alignItems="center" gap={2}>
              <CardMedia
                component="img"
                src={
                  avatar
                    ? require(`assets/characters/${avatar}/avatar.png`)
                    : undefined
                }
                sx={{
                  backgroundImage: `url(./background-avatar.svg)`,
                  width: "13rem",
                  height: "13rem",
                  padding: "2.5rem",
                }}
              />
              <Divider flexItem orientation="horizontal" />
              <TextField
                placeholder={t("Pseudo")}
                value={pseudo}
                onChange={(event) => setPseudo(event.target.value)}
              />
              <Divider flexItem orientation="horizontal" />
            </Stack>
            <GenshinButton
              disabled={avatar === "" || pseudo === ""}
              loading={loading}
              variant="contained"
              onClick={handleValidate}
            >
              {t("Valider")}
            </GenshinButton>
          </Stack>
        </Stack>
      </DialogContent>
    </GenshinDialog>
  );
};

export default AvatarSelector;
