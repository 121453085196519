import API from "service/root/rootApi";
import { DraftParams } from "./type";

export const getDraftParams = async (
  scorePlayer1: number,
  scorePlayer2: number
) => {
  const response = await API.post<DraftParams>("/draftParams", {
    scorePlayer1,
    scorePlayer2,
  });
  return response.data;
};
