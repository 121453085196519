import {
  createTheme as muiCreateTheme,
  PaletteOptions,
  Theme,
} from "@mui/material";
import ThemeProvider, {
  ThemeProviderProps,
} from "@mui/material/styles/ThemeProvider";
import { radius, components } from "./const";

export type ThemeMode = "dark";
export const TypedThemeProvider = (props: ThemeProviderProps<Theme>) =>
  ThemeProvider<Theme>(props);

export const createTheme = (mode: ThemeMode = "dark") => {
  const palette = palettes[mode];
  return muiCreateTheme({
    palette,
    radius,
    typography: {
      fontFamily: "GenshinImpact",
      allVariants: {
        textTransform: "none",
      },
    },
    components,
    shape: {
      borderRadius: 12,
    },
  });
};

export const darkPalette: PaletteOptions = {
  mode: "dark",
  background: {
    dark: "#12141C",
    default: "#191C28",
    paper: "#393E52",
    highlight: "#30323D",
    highlighter: "#3D404A",
    ban: "#7B0000",
    pick: "#20B3E4",
    button: "#ECE5D8",
  },
  common: {
    grey: {
      light: "#9B9B9B",
      dark: "#555555",
      g9B9B9B: "#9B9B9B",
      g474a53: "#474a53",
    },
    player1: {
      text: "#20C9FF",
      paper: "#10364B",
      banCard: "#20B3E4",
      bgConstel: "#0c6b9f",
      borderConstel: "#247d9b",
    },
    player2: {
      text: "#FF4444",
      paper: "#482118",
      banCard: "#FF4444",
      bgConstel: "#a7351b",
      borderConstel: "#FF4444",
    },
  },
};

export const palettes: { [K in ThemeMode]: PaletteOptions } = {
  dark: darkPalette,
};
