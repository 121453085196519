import { Avatar } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

type ProfileProps = {};

const Profile = (props: ProfileProps) => {
  const { t } = useTranslation();

  return (
    <Avatar
      sx={{
        height: "4rem",
        width: "4rem",
      }}
    ></Avatar>
  );
};

export default Profile;
