import { useEffect, useState } from "react";

export const useInputKeyEvent = () => {
  const [key, setKey] = useState<string | null>(null);

  useEffect(() => {
    const keyDownHandler = ({ code }) => setKey(code);
    const keyUpHandler = () => setKey(null);
    global.addEventListener("keydown", keyDownHandler);
    global.addEventListener("keyup", keyUpHandler);
    return () => {
      global.removeEventListener("keydown", keyDownHandler);
      global.removeEventListener("keyup", keyUpHandler);
    };
  }, []);

  return key;
};

const useSecretCode = (secretCode: string[]) => {
  const [count, setCount] = useState<number>(0);
  const [success, setSuccess] = useState<boolean>(false);
  const key = useInputKeyEvent();

  useEffect(() => {
    if (key == null) return;
    if (key !== secretCode[count]) {
      setCount(0);
      return;
    }

    if (count + 1 === secretCode.length) {
      setSuccess(!success);
      setCount(0);
    } else setCount(count + 1);
  }, [key]);

  return success;
};

const konamiCode = [
  "ArrowUp",
  "ArrowUp",
  "ArrowDown",
  "ArrowDown",
  "ArrowLeft",
  "ArrowRight",
  "ArrowLeft",
  "ArrowRight",
  "KeyB",
  "KeyQ",
];

export const useKonamiCode = () => {
  const success = useSecretCode(konamiCode);
  return success;
};
